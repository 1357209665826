import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect } from "react"
import { Slide, ToastContainer } from "react-toastify"
import KeyboardAction from "./KeyboardAction"
import LoadingLogo from "./LoadingLogo"
import Onboarding from "./Onboarding"
import NotificationRequest from "./common/NotificationRequest"
import "@/styles/lexicalStyles.module.css"

export interface LoadingScreenProps {
  children: React.ReactNode
  showLoading?: boolean
}

const LoadingScreen = ({ children, showLoading = true }: LoadingScreenProps) => {
  const { loading, userInfoQuery } = useAuth()

  useEffect(() => {
    if (userInfoQuery?.data && userInfoQuery.isSuccess) {
      googleAnalytics.event({
        action: "user_capacity",
        label: "User Capacity",
        category: "image",
        params: {
          total_size: `${(userInfoQuery.data.storageSize / 1024).toFixed(2)}`,
        },
      })
    }
  }, [userInfoQuery?.data, userInfoQuery?.isSuccess])

  return (
    <>
      {children}
      <Onboarding />
      {!loading && <KeyboardAction />}
      <AnimatePresence initial={false} mode="wait">
        {showLoading && loading ? (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.35 } }}
            className="fixed top-0 left-0 w-full h-full z-[99] flex items-center justify-center bg-atherGray-950"
          >
            <LoadingLogo />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        toastStyle={{ backgroundColor: "#333333" }}
        theme="dark"
        transition={Slide}
      />
      <NotificationRequest className="z-[99999]" />
    </>
  )
}

export default LoadingScreen
