import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { Fragment, useState } from "react"
import CheckboxComponent from "../Checkbox"
import IconButton from "../IconButton"
import Modal from "../Modal"
import { cdnPublicFolderUrl } from "@/constants"

const dataStep1 = ["Marketing", "Game Assets", "Concept Art", "Commercial", "For fun"]
const dataStep2 = ["Stable Diffusion", "Mid Journey", "Leonardo AI", "Adobe Photoshop AI", "Runway AI", "Other"]
const dataStep3 = ["Pro Mode", "Lite Mode"]

const Onboarding = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSelector, setIsOpenSelector] = useState(false)
  const [index, setIndex] = useState(0)
  const [isFinal, setIsFinal] = useState(false)
  const [stepData, setStepData] = useState<{
    step1: string
    step2: { values: string[]; other?: string }
    step3: string
  }>({
    step1: "",
    step2: {
      values: [],
      other: undefined,
    },
    step3: "",
  })

  const onClose = () => {
    googleAnalytics.event({
      action: "click",
      category: "onboarding",
      label: "close-onboarding",
      params: {
        step: index + 1,
        data_step_1: stepData.step1,
        data_step_2: stepData.step2.values.join(","),
        data_step_2_other: stepData.step2.other || "",
        data_step_3: stepData.step3,
      },
    })

    setIsOpen(false)
  }

  const handleNext = () => {
    googleAnalytics.event({
      action: "click",
      category: "onboarding",
      label: "next-step",
      params: {
        step: index + 1,
        data_step_1: stepData.step1,
        data_step_2: stepData.step2.values.join(","),
        data_step_2_other: stepData.step2.other || "",
        data_step_3: stepData.step3,
      },
    })

    if (index < 4) {
      setIndex(prev => prev + 1)

      if (index === 3) {
        setIsFinal(true)
      }
    }
  }

  const renderStep = () => {
    switch (index) {
      case 0:
        return (
          <div className="flex flex-col items-center flex-1">
            <div className="flex flex-wrap items-end py-16">
              <h2 className="text-3xl font-semibold mr-3 leading-7 text-atherGray-50">Welcome to</h2>
              <img className="" src={`${cdnPublicFolderUrl}/text-logo.png`} alt="" />
            </div>
            <p className="text-base font-semibold text-center mt-14 mb-2 text-atherGray-300">
              Thanks for starting your artistic imagination journey with GAIA!
            </p>
            <p className="text-base font-semibold text-center mb-8 text-atherGray-300">
              We&apos;ll begin with a spin..
            </p>
            <div className="relative">
              <IconButton onClick={handleNext} className="px-6 py-3 rounded-2xl">
                Let&apos; start
              </IconButton>
              <div className="absolute right-[-0.5rem] top-1/2 -translate-y-1/2 translate-x-full">
                <div className="ml-2 bg-atherGray-850 py-1 px-2 flex justify-center items-center font-semibold text-[#EAE4D4] rounded-lg relative">
                  <div
                    className="bg-atherGray-850 absolute -left-[0.45rem] top-1/2 -translate-y-1/2 w-2 h-2 rotate-180"
                    style={{
                      clipPath: "polygon(80% 50%, 0 0, 0 100%)",
                    }}
                  />
                  <div className="flex items-center">
                    <img src="https://cdn.protogaia.com/resources/default-avatar.png" alt="" className="w-8 h-8 mr-2" />
                    <p className="text-base leading-4">
                      +100
                      <span className="block text-[0.65rem]">AV COINS</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <IconButton colorScheme="transparent" className="text-base text-atherGray-300 font-semibold mt-6">
              Do this later
            </IconButton>
          </div>
        )

      case 1:
        return (
          <div className="flex flex-col items-center flex-1">
            <div className="flex flex-wrap items-end py-8">
              <img className="h-5" src={`${cdnPublicFolderUrl}/text-logo.png`} alt="" />
            </div>
            <p className="font-semibold">Help us understand you</p>
            <p className="text-atherGray-300">We&apos;ll use this to customize your onboarding experience.</p>
            <div className="mt-6 w-full max-w-[24.5rem] flex-1">
              <p className="text-atherGray-300 mb-1">What will you be using GAIA for?</p>
              <div className="relative text-atherGray-0">
                <div
                  onClick={() => setIsOpenSelector(prev => !prev)}
                  className={classNames("bg-atherGray-800 py-3 px-4 border rounded-2xl", {
                    "border-atherGray-500": isOpenSelector,
                    "border-atherGray-800": !isOpenSelector,
                  })}
                >
                  {stepData.step1 ? stepData.step1 : "Select option"}
                </div>
                <AnimatePresence>
                  {isOpenSelector && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{ height: 0 }}
                      transition={{ duration: 0.15 }}
                      className="overflow-hidden absolute top-full -translate-y-[0.25rem] left-0 bg-atherGray-900 border border-atherGray-800 rounded-2xl w-full mt-2"
                    >
                      <div className="p-2">
                        {dataStep1.map((item, idx) => (
                          <div
                            key={idx}
                            onClick={() => {
                              setStepData(prev => ({ ...prev, step1: item }))
                              setIsOpenSelector(false)
                            }}
                            className="py-2 rounded-2xl px-4 cursor-pointer hover:bg-atherGray-850"
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <IconButton
              disabled={!stepData.step1}
              onClick={handleNext}
              className="px-6 py-3 w-full max-w-[14rem] rounded-2xl"
            >
              Next
            </IconButton>
          </div>
        )

      case 2:
        return (
          <div className="flex flex-col items-center flex-1">
            <div className="flex flex-wrap items-end py-8">
              <img className="h-5" src={`${cdnPublicFolderUrl}/text-logo.png`} alt="" />
            </div>
            <p className="font-semibold">Have you ever used platforms similar to GAIA?</p>
            <div className="mt-6 w-full max-w-[24.5rem] space-y-4 text-atherGray-300 flex-1">
              {dataStep2.map(item => (
                <Fragment key={item}>
                  <CheckboxComponent
                    label={item}
                    checked={stepData.step2.values.includes(item)}
                    onChange={() => {
                      if (item === "Other") {
                        setStepData(prev => ({
                          ...prev,
                          step2: { ...prev.step2, other: "" },
                        }))
                      }

                      if (stepData.step2.values.includes(item)) {
                        setStepData(prev => ({
                          ...prev,
                          step2: { ...prev.step2, values: prev.step2.values.filter(i => i !== item) },
                        }))
                      } else {
                        setStepData(prev => ({
                          ...prev,
                          step2: { ...prev.step2, values: [...prev.step2.values, item] },
                        }))
                      }
                    }}
                  />
                  {item === "Other" && stepData.step2.values.includes(item) && (
                    <input
                      className="bg-atherGray-800 py-3 px-4 border border-atherGray-800 rounded-2xl w-full placeholder:text-atherGray-500"
                      placeholder="Please specify"
                      value={stepData.step2.other}
                      onChange={e => {
                        if (stepData.step2.values.includes("Other")) {
                          setStepData(prev => ({
                            ...prev,
                            step2: { ...prev.step2, other: e.target.value },
                          }))
                        }
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </div>
            <IconButton
              disabled={!stepData.step2.values.length}
              onClick={handleNext}
              className="px-6 py-3 w-full max-w-[14rem] rounded-2xl"
            >
              Next
            </IconButton>
          </div>
        )

      case 3:
        return (
          <div className="flex flex-col items-center flex-1">
            <div className="flex flex-wrap items-end py-8">
              <img className="h-5" src={`${cdnPublicFolderUrl}/text-logo.png`} alt="" />
            </div>
            <p className="font-semibold">Choose a suitable path go get started</p>
            <div className="mt-6 w-full max-w-[30rem] flex flex-col text-atherGray-300 flex-1">
              <div className="space-x-6 flex w-full h-[14rem] mb-4">
                {dataStep3.map(item => (
                  <div
                    onClick={() => setStepData(prev => ({ ...prev, step3: item }))}
                    className={classNames(
                      "w-full hover:bg-atherGray-800 bg-atherGray-850 border cursor-pointer rounded-2xl flex items-center justify-center font-semibold",
                      {
                        "border-atherPurple-500": stepData.step3 === item,
                        "border-atherGray-850": stepData.step3 !== item,
                      },
                    )}
                    key={item}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <p className="text-center">You can change the path later in Account Management</p>
            </div>
            <IconButton
              disabled={!stepData.step3}
              onClick={handleNext}
              className="px-6 py-3 w-full max-w-[14rem] rounded-2xl"
            >
              Next
            </IconButton>
          </div>
        )

      case 4:
        return (
          <div className="flex flex-col items-center flex-1">
            <div className="flex flex-wrap items-end py-8">
              <img className="h-5" src={`${cdnPublicFolderUrl}/text-logo.png`} alt="" />
            </div>
            <div className="mt-6 w-full max-w-[25rem] flex flex-col text-atherGray-300 flex-1">
              <div className="flex items-center justify-center mb-8">
                <img src="https://cdn.protogaia.com/resources/default-avatar.png" alt="" className="mr-2" />
                <p className="text-lg font-semibold leading-5">
                  +100
                  <span className="block text-xs">AV COINS</span>
                </p>
              </div>
              <p className="font-semibold text-center text-xl text-atherGray-0">
                Time to start your Journey Create your story and share it with the world!
              </p>
            </div>
            <div className="w-full flex justify-center border-t border-atherGray-800 pt-6">
              <p className="text-atherGray-500">
                Need help?{" "}
                <Link
                  onClick={() => {
                    googleAnalytics.event({
                      action: "click",
                      category: "contact-us",
                      label: "mailto:hello@protogaia.com",
                    })
                  }}
                  href="mailto:hello@protogaia.com"
                  target="_blank"
                  className="text-atherGray-0 font-semibold"
                  rel="noopener noreferrer"
                >
                  Contact us
                </Link>
              </p>
            </div>
          </div>
        )

      default:
        break
    }
  }

  return (
    <Modal className="max-w-4xl min-h-[38rem]" isOpen={isOpen} onClose={onClose}>
      <motion.div
        key={index}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        className="flex-1 flex-col flex"
      >
        {renderStep()}
      </motion.div>
      {!isFinal && (
        <div className="flex items-center justify-center space-x-2 pb-4 pt-8">
          {Array.from({ length: 5 }).map((_, idx) => (
            <div
              onClick={() => {
                if (index === idx) return

                if (!isFinal) return

                setIndex(idx)
              }}
              key={idx}
              className={classNames("w-2 h-2 rounded-full cursor-pointer", {
                "bg-atherGray-300": index === idx,
                "bg-atherGray-800": index !== idx,
              })}
            />
          ))}
        </div>
      )}
    </Modal>
  )
}

export default Onboarding
