import { app } from "@/lib/firebaseClient"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useMutateAddFcmToken } from "@/queries/useMutateAddFcmToken"
import { getMessaging, getToken, isSupported } from "firebase/messaging"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import IconButton from "../IconButton"
import ReactPortal from "../ReactPortal"

export const requestNotificationPermission = async (): Promise<NotificationPermission> => {
  if (!("Notification" in window)) {
    return "denied"
  }
  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission()

    return permission
  }
  return "denied"
}

const generateFcmToken = async (): Promise<string | null> => {
  const supported = await isSupported()
  if (!supported || !navigator) {
    console.log("FCM is not supported")
    return null
  }
  try {
    const registration = await navigator.serviceWorker.ready
    const messaging = getMessaging(app)
    const fcmToken = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FCM_VAPID_KEY,
      serviceWorkerRegistration: registration,
    })
    if (!fcmToken) {
      console.log("No registration token available. Request permission to generate one.")
      return null
    }
    return fcmToken
  } catch (err) {
    console.error("An error occurred while retrieving token", err)
  }
  return null
}

const NotificationRequest = ({ className }: { className?: string }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isDisabled, setDisabled] = useState<boolean>(false)
  const { user } = useAuth()

  const { mutate: mutateAddFcmToken } = useMutateAddFcmToken()

  useEffect(() => {
    if (localStorage.getItem("first-time-notification")) return

    if (!("Notification" in window)) {
      return
    }

    if (Notification.permission !== "default") {
      return
    }
    const timer = setTimeout(() => {
      if (isMobile) {
        setOpen(true)
      } else {
        requestNotificationPermission()
          .then(permission => {
            if (permission === "granted") {
              generateFcmToken()
                .then(fcmToken => {
                  if (fcmToken) localStorage.setItem("fcm-token", fcmToken)
                })
                .catch(err => console.error(err))
            }
          })
          .catch(err => console.error(err))
      }
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!("Notification" in window)) {
      return
    }
    const currentFcmToken = localStorage.getItem("fcm-token")
    // Remove token when user off notification permission in browser (or OS) settings
    if (Notification.permission === "denied" && currentFcmToken) {
      console.log("Notification permission is denied, removing current token")
      localStorage.removeItem("fcm-token")
    }
    if (Notification.permission === "granted" && !currentFcmToken && user) {
      console.log("Notification permission is granted, current token not found. Requesting for token")
      generateFcmToken()
        .then(fcmToken => {
          if (fcmToken) {
            localStorage.setItem("fcm-token", fcmToken)
            mutateAddFcmToken({ token: fcmToken })
          }
        })
        .catch(err => console.error("Failed to generate fcm token", err))
    }
  }, [mutateAddFcmToken, user])

  const handleTurnOnNotification = async () => {
    setDisabled(true)
    const permission = await requestNotificationPermission()
    if (permission === "granted") {
      // Using .then to avoid blocking the modal turning off when firebase api hangs
      generateFcmToken()
        .then(fcmToken => {
          if (fcmToken) localStorage.setItem("fcm-token", fcmToken)
        })
        .catch(err => console.error(err))
    }

    localStorage.setItem("first-time-notification", "true")
    setDisabled(false)
    setOpen(false)
  }

  const onClose = () => {
    localStorage.setItem("first-time-notification", "true")
    setOpen(false)
  }

  return (
    <ReactPortal>
      <AnimatePresence mode="wait">
        {isOpen && isMobile && (
          <motion.div
            initial={{
              opacity: 0,
              y: -25,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: -25,
            }}
            transition={{
              ease: "easeInOut",
              duration: 0.35,
            }}
          >
            <div
              className={cn(
                "w-full md:max-w-80 flex flex-col text-atherGray-0 bg-atherGray-900 absolute top-0 left-0 right-0 ml-auto mr-auto p-4 rounded-b-lg",
                className,
              )}
            >
              <div className="text-sm flex items-center">
                <img
                  src={"https://cdn.protogaia.com/resources/public/logomark.png"}
                  alt="logo"
                  width={40}
                  height={40}
                />
                <div className="ml-4">
                  Stay connected! Enable notifications to receive updates on your works and community.
                </div>
              </div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  type: "tween",
                  duration: 0.3,
                  ease: "easeInOut",
                }}
              >
                <div className="flex justify-end mt-2">
                  <div className="flex">
                    <IconButton
                      onClick={async () => {
                        await handleTurnOnNotification()
                      }}
                      colorScheme="primary"
                      className="p-2"
                      disabled={isDisabled}
                    >
                      Allow
                    </IconButton>
                    <IconButton onClick={onClose} colorScheme="secondary" className="p-2 ml-3" disabled={isDisabled}>
                      Later
                    </IconButton>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ReactPortal>
  )
}

export default NotificationRequest
