import client from "@/api/client"
import { useCallbackOnHotKeys } from "@/hooks"
import { useCreateNewStyleMutation } from "@/queries"
import { useManagementErrorsStore, useShortcutKeyboardStore } from "@/stores"
import { useMutation } from "@tanstack/react-query"
import { defaultPrompt, defaultWorkflow } from "./Tools/ComfyUIRecipe/default-workflow"
import _isEmpty from "lodash/isEmpty"
import useCustomRouter from "@/hooks/useCustomRouter"

const KeyboardAction = () => {
  const router = useCustomRouter()
  const setIsOpen = useShortcutKeyboardStore(state => state.setIsOpen)
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const { openNewTab } = useCustomRouter()

  const { mutate: createStyle } = useCreateNewStyleMutation({
    onSuccess(created) {
      setTimeout(() => {
        openNewTab(`/workspace/tools/styles/${created.id}`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
    },
  })

  const { mutate: createWorkflow } = useMutation({
    mutationFn: async () => {
      return client.api
        .sdWorkflowControllerCreateWorkflow({
          name: "My Workflow",
          workflow: defaultWorkflow,
          prompt: defaultPrompt,
          thumbnailFileId: null,
        })
        .then(res => res.data)
    },
    onSuccess(created) {
      setTimeout(() => {
        openNewTab(`/workspace/tools/comfyui/${created.id}`)
      }, 1)
    },
  })

  useCallbackOnHotKeys(
    "shift+w",
    () => {
      router.push("/workspace")
    },
    true,
  )

  useCallbackOnHotKeys(
    "shift+m",
    () => {
      setTimeout(() => {
        openNewTab("/workspace/macros/new-macro")
      }, 1)
    },
    true,
  )

  useCallbackOnHotKeys(
    "shift+s",
    () => {
      createStyle({
        images: [],
        isDraft: true,
      })
    },
    true,
  )

  useCallbackOnHotKeys(
    "shift+c",
    () => {
      createWorkflow()
    },
    true,
  )

  useCallbackOnHotKeys(
    "shift+q",
    () => {
      setIsOpen(true)
    },
    true,
  )

  return <></>
}

export default KeyboardAction
